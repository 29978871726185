import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import es from './translations/es.json';
import en from './translations/en.json';
import pt from './translations/pt.json';
import zh from './translations/zh.json';
import de from './translations/de.json';
import fr from './translations/fr.json';
import it from './translations/it.json';
import fl from './translations/fl.json';
import pl from './translations/pl.json';
import ru from './translations/ru.json';
import sw from './translations/sw.json';

const messages = {
  es: es,
  en: en,
  pt: pt,
  de: de,
  fr: fr,
  it: it,
  fl: fl,
  pl: pl,
  ru: ru,
  sw: sw,
  'zh-cn': zh
};

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'en',
  messages
});

const app = createApp(App);

app.use(i18n);
app.mount('#app');
