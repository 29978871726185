<template>
  <div class="rm-lang">
    <button id="lang-select" @click="toggleDropdown">
      <img
        id="lang-image"
        :src="selectedLanguage.flag"
        alt="USD"
      />
      <div class="lang-content">
        <span id="lang-name">{{ selectedLanguage.name }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          ></path>
        </svg>
      </div>
    </button>
    <div id="lang-options" v-show="isDropdownOpen" @click.stop>
      <a v-for="language in languages" :key="language.code" @click="selectLanguage(language)">
        <div style="display: flex; justify-content: center; align-items: center;">
          <img :src="language.flag" style="height: 11px;" alt="Language Flag" />
        </div>
        <span>{{ language.name }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LangDrop",
  data() {
    return {
      isDropdownOpen: false,
      selectedLanguage: {},
      languages: [
        {
          code: "en",
          name: "English",
          flag: require('@/assets/images/en.svg'),
        },
        {
          code: "es",
          name: "Español",
          flag: require('@/assets/images/es.svg'),
        },
        {
          code: "pt",
          name: "Português",
          flag: require('@/assets/images/pt.svg'),
        },
        {
          code: "fr",
          name: "Français",
          flag: require('@/assets/images/fr.svg'),
        },
        {
          code: "it",
          name: "Italiano",
          flag: require('@/assets/images/it.png'),
        },
        {
          code: "fl",
          name: "Filipino",
          flag: require('@/assets/images/fl.png'),
        },
        {
          code: "pl",
          name: "Polski",
          flag: require('@/assets/images/pl.png'),
        },
        {
          code: "ru",
          name: "Русский",
          flag: require('@/assets/images/ru.png'),
        },
        {
          code: "sw",
          name: "Kiswahili",
          flag: require('@/assets/images/sw.png'),
        },
        {
          code: "zh-cn",
          name: "英语",
          flag: require('@/assets/images/cn.svg'),
        },
        {
          code: "de",
          name: "Deutsch",
          flag: require('@/assets/images/de.svg'),
        },
        // Agrega más idiomas aquí...
      ],
    };
  },
  mounted() {
    this.selectLanguageByCode(navigator.language.slice(0, 2));
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectLanguage(language) {
      this.selectedLanguage = language;
      this.isDropdownOpen = false;
      // Aquí puedes agregar la lógica para cambiar el idioma de tu aplicación
      this.$i18n.locale = language.code;
    },
    selectLanguageByCode(code) {
      const language = this.languages.find(lang => lang.code === code);
      if (language) {
        this.selectedLanguage = language;
        this.$i18n.locale = language.code;
      } else {
        // Si el idioma del usuario no se encuentra en la lista, selecciona un idioma predeterminado
        this.selectedLanguage = this.languages[0];
        this.$i18n.locale = this.languages[0].code;
      }
    }
  },
};
</script>

<style>
.rm-lang{
  margin-left: 20px;
}
.rm-lang button {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  border: 1px solid #eee;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
}
.rm-lang img {
  width: 15px;
}

.rm-lang #lang-options {
  margin-top: 5px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0px 10px 0px #d7d7d7;
  position: absolute;
}
.rm-lang #lang-options a {
  display: flex;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom:none!important
}
.rm-lang #lang-options a:hover {
  background: #cee5ff;
  border-bottom:none!important
}
.rm-lang #lang-options a span{
  font-size: 16px;
  margin-left: 10px;
}
#lang-select svg {
  width: 16px;
}
.lang-content {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
</style>
